import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from '../../../../node_modules/rxjs';
import { take, map } from '../../../../node_modules/rxjs/operators';
import { GroupsService } from '../data/groups.service';

@Injectable()
export class GroupResolver implements Resolve<Observable<any>> {

    constructor(private groupsService: GroupsService) { }
  

resolve(route: ActivatedRouteSnapshot): Observable<any> { 
    const uid = route.paramMap.get('uid'); 

    // Directly return the observable from the service without subscribing to it
    return this.groupsService.getGroup(uid).pipe(
      take(1), // Ensure the observable completes after emitting once
      map(response => { 
        return response; // Return the resolved data
      })
    );
  }
}
