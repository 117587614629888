import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../data/users.service';
import { take, map } from 'rxjs/operators';
import { UserProfileResponse } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserProfileResolver implements Resolve<Observable<any>> {

  constructor(private userService: UserService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const id = route.paramMap.get('uid');

    // Directly return the observable from the service without subscribing to it
    return this.userService.getUserProfileData().pipe(
      take(1), // Ensure the observable completes after emitting once
      map(response => { 
        return response; // Return the resolved data
      })
    );
  }
}
