import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// import { getDeepFromObject } from '@nebular/auth/helpers';
import { User, LoginResult } from '../../../../@core/models/user.model';
import { generalConfig } from '../../../../../general-config';
import { UserService } from '../../../../@core/data/users.service';


@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  formData: { user: User, rememberMe: boolean }
  errors: string[] = [];
  isSubmitting: boolean = false;
  socialLinks = [];
  constructor(
    private router: Router,
    private userService: UserService,
  ) {

  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.formData = { user: {}, rememberMe: true };
    this.socialLinks = generalConfig.socialLinks;
  }


  login(): void {
    // this.isSubmitting = true 
    this.userService.logout() 
    this.userService.isubmitting_login =true;
    this.errors = [];
    this.userService.errors_login = []
    this.userService.login(this.formData.user)
      .subscribe(
        (res:any) => {
          this.userService.setUserData(res)
          this.router.navigate(['/pages/profile']);
        },
        errRes => {
          // this.isSubmitting = false;
          this.userService.isubmitting_login =false;
          if (errRes.error.message) {
            this.errors.push(errRes.error.message);
          } else {
            this.errors.push(generalConfig.errorMsg);

          }
        },
    );
  }
 
 
 



  // todo: move to util service
  getGeneralConfigValue(key: string): any {
    // return getDeepFromObject(generalConfig, key, null);
  }
}
