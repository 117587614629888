// export const hypegraphConfig = {
//     base: 'http://46.101.47.50:8000/services/api/graph',
//     site: 'se1',
//     publicKey: '4adf4304e08f3c0acd12056acac756eb1a26fa49',
//     privateKey: 'dacd47c1caf332296340e1f258c50f33fdc345e4',
//     limit: 9, 
//     re7: true
// }
// export const file_srv = {
//     base: 'http://46.101.47.50/'
// }

// export const convertConfig = {
//     base: 'http://46.101.47.50:9001/convert',
// }
// export const feather = {
//     base: 'http://46.101.47.50:5051',
//     limit: 10
// }

// export const template_srv = {
//     base: 'http://46.101.47.50:6061',
//     limit: 10,
// }
// export const template_sig = {
//     base: 'http://46.101.47.50:5051',
//     limit: 10,
// } 
// export var  vr_tour_prdt = {
//     base: 'http://46.101.47.50:4041',
//     limit: 10,
//     s_token:"68bh88"
// }
export const hypegraphConfig = {
    base: 'https://api.caza3d.com/services/api/graph',
    site: 'se1',
    publicKey: '40358247ef76ec9c59842698e2c7eadb373f2572',
    privateKey: 'zwyhad80EcT_HvvUvsRV2I4eAkn3WHw4',
    limit: 9,
   re7: false
}
export const auth = {
    base: 'https://auth.caza3d.com/'
}
export const file_srv = {
    base: 'https://caza3d.com/'
}
export const convertConfig = {
    base: 'https://caza3d.com:9001/convert',
}
export const feather = {
    base: 'https://filesys.caza3d.com',
    limit: 10,
}
export const template_srv = {
    base: 'https://template.caza3d.com',
    limit: 10,
}
export const template_sig = {
    base: 'https://filesys.caza3d.com',
    limit: 10,
}
export const vr_tour_prdt = {
    base: 'https://vrtour.caza3d.com',
    limit: 10,
    s_token:"68bh88"
}