import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { UserService } from '../data/users.service';

@Injectable()
export class LoggedInGuard implements CanActivate, CanLoad {
    constructor(
        private router: Router,
        private userService: UserService,
    ) {

    }
    canActivate() {
        return this.checkLoggedIn();
    }

    canLoad() {
        return this.checkLoggedIn();
    }

    checkLoggedIn(): Promise<boolean> {
        // const isLoggedIn = this.userService.isLoggedIn();
        
        // console.log("isLoggedIn", isLoggedIn);
        // if (!isLoggedIn) {
        //     this.router.navigate(['/auth/login']);
        //     return Promise.resolve(false); // User is not logged in
        // }
    
        return this.userService.getUserProfileData().toPromise().then(
            res => {console.log('yes connected ',res )
                // Handle successful profile data retrieval if needed
                if ( res.name === 'NotAuthenticated') {
                    // this.router.navigate(['/auth/login']);
                    return false
                }else{
                     return true; // User is logged in
                }
               
            },
            errRes => {
                console.log('not connected ')
                this.router.navigate(['/auth/login']);
                return false; // User is not logged in
            }
        );
    }
    
}
