import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {UserService} from '../../../../@core/data/users.service'
import { resolve } from 'url';

@Component({
  selector: 'nb-request-password-page',
  styleUrls: ['./request-password.component.scss'],
  templateUrl: './request-password.component.html', 
})
export class RequestPasswordComponent {

  redirectDelay: number = 0;
  showMessages: any = {};
  strategy: string = ''; 
  submitted = false;
  errors: string[] = [];
  messages: string[] = [];
  user: any = {};

  constructor( 
              protected router: Router,
              protected UserService :UserService,
              
              ) {

    // this.redirectDelay = this.getConfigValue('forms.requestPassword.redirectDelay');
    // this.showMessages = this.getConfigValue('forms.requestPassword.showMessages');
    // this.strategy = this.getConfigValue('forms.requestPassword.strategy');
  }

  requestPass(): void {
    this.errors = this.messages = [];
    this.submitted = true; 
    console.log(this.user.email)

  this.UserService.requestPassword(this.user.email).subscribe((result) =>{

console.log(result)

  })
    // this.service.requestPassword(this.strategy, this.user).subscribe((result: NbAuthResult) => {
    //   this.submitted = false;
    //   if (result.isSuccess()) {
    //     this.messages = result.getMessages();
    //   } else {
    //     this.errors = result.getErrors();
    //   }

    //   const redirect = result.getRedirect();
    //   if (redirect) {
    //     setTimeout(() => {
    //       return this.router.navigateByUrl(redirect);
    //     }, this.redirectDelay);
    //   }
    //   // this.cd.detectChanges();
    // });
  }

  
}
