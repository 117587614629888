import { Injectable } from '@angular/core'; 
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
}) 
export class modalService_data {


  private heightSource = new BehaviorSubject<number>(0);
  currentHeight = this.heightSource.asObservable();

  
  btn_oui: string;
  btn_annuler: string;
  btn_fermer: string;
  btn_sauvegarder: string;
  enabled: boolean;
  headtitle: string;
  content_text: string;
  update_card: boolean;
  update_account: boolean;
  name: string;
  card: any
  profile: any
  facture: any
  offre_service: any
  offre_produit: any
  messages: any
  perf: any
  abo: any
  footer_disabled: boolean
  subscribeTo: string;
  basket: any;
  card_booked_total: string
  showform_submit: boolean
  show_paybutton: boolean
  cardID: any;
  cardAndPayTotal: any
  cardAlreadySaved: any
  active_sub: any
  active_sub_id: any
  dbData_imgCover = [];
  tag_list = [];
  country_list = [];
  taget_user_uid: string
  actived_advanced: boolean;
  actived_decouvert: boolean;
  actived_pro: boolean= false;
  subscriber_payed:boolean
  actived_entreprise: boolean;
  actived_expert: boolean;
  post_to_save: any;
  post_desc: any;
  publish_post: any = 0
  header_show:boolean  
  header_height:boolean  
  show_nav:boolean  
  constructor() {
  }
  clean() {
    this.showform_submit = false;
    this.show_paybutton = false;
    this.cardAndPayTotal = ""
    this.btn_oui = ""
    this.btn_annuler = ""
    this.btn_fermer = ""
    this.btn_sauvegarder = ""
    this.enabled = false;
    this.headtitle = ""
    this.content_text = ""
    this.update_card = false;
    this.update_account = false;
    this.name = ""
    this.card = {}
    this.profile = {}
    this.facture = {}
    this.offre_service = {}
    this.offre_produit = {}
    this.messages = {}
    this.perf = {}
    this.abo = {}

    this.footer_disabled = false;
    this.header_show = false;
    this.subscribeTo = ""
    this.active_sub = {}

     this.actived_decouvert = false // deprecated 30€ +1 ans + un site web de gestion de doc +sans collegue + logo caza3d 
     this.actived_expert = false   // deprecated 90€ /agence + nom de domaine dedier + template + landing/seo + e-mail first page + sans logo 
    this.actived_advanced = false  // deprecated /300 support  premium +remplacer hector 
    this.actived_entreprise = false //
    this.actived_pro = false //subscriber_payed 30 bucks 
this.subscriber_payed = false



  }
  changeHeight(height: number) {
    this.heightSource.next(height);
  }
}
