import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { UserService } from '../data/users.service';
import { tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EMPTY, throwError } from 'rxjs';
@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(
    private userService: UserService,
    private router: Router,
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log("touchdownd" ,this.router.url)
    //docu allow none login pages here / and with canActivate
    // if (this.router.url.toString().lastIndexOf('faq') > 0
    // || window.location.href.toString().lastIndexOf('home') > 0
    // || window.location.href.toString().lastIndexOf('onboard') > 0
    // || window.location.href.toString().lastIndexOf('faq-pro') > 0 
    // ){


    // }

    if (this.userService.isLoggedIn()) {
 const token = this.userService.getToken()
      request = request.clone({ 
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
   } 
    return next.handle(request).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
          }
        },
      ),
      catchError(
        err => {
          // this.userService.isubmitting_login =false;
          // this.userService.errors_login.push('Erreur de connection')
          // console.log("interceped http error", err)
          if (err.status === 401 || err.status === 404) { 
            if ( request.url.toString().lastIndexOf('explore') > 0 ||
                 //request.url.toString().lastIndexOf('me') > 0 ||//this endpoint require a connected user
                 request.url.toString().lastIndexOf('profile') > 0   
            ){        
                this.router.navigate(['/auth/login']);
            }
            // this.userService.logout();
            // 
            return EMPTY;
          }  else {
            return throwError(err);
          }
        },
      ),
    );
  }

}
