import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import {hypegraphConfig} from '../../../api-config';
import { Observable } from 'rxjs/Observable';
@Injectable()
export class ApiTokenInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone({
      setParams: {
        // 'api_key': hypegraphConfig.publicKey,
      },
    });
    return next.handle(request);
  }
}
