import { Injectable } from '@angular/core'; 

@Injectable()
export class SvcmodalService_data {
  btn_oui: string;
  btn_annuler :string;
  btn_fermer:string;
  btn_sauvegarder :string;
  enabled: boolean;
  headtitle  :string;
  content_text :string; 
  name :string; 
  profile:any 
  footer_disabled:boolean 
  showform_submit :boolean 
  dbData_imgCover= [];
  tag_list = [];
  country_list= [];
  taget_user_uid  : string
  post_to_save : any;
  post_desc:any;
  publish_post:any = 0
  constructor( ) { 
  }
 clean(){
  this.showform_submit= false; 
  this.btn_oui=""
  this.btn_annuler =""
  this.btn_fermer=""
  this.btn_sauvegarder=""
  this.enabled=false;
  this.headtitle  =""
  this.content_text ="" 
  this.name ="" 
  this.profile={} 
this.footer_disabled=false; 
 }
}
