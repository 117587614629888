import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from '../../../../node_modules/rxjs';
import { take, map } from '../../../../node_modules/rxjs/operators';
import { hypegraphConfig } from '../../../api-config';
import { UserService } from '../data/users.service';

@Injectable()
export class UserMembershipResolver implements Resolve<Observable<any>> {

    constructor(private userService: UserService) { }

    resolve(route: ActivatedRouteSnapshot) {
        const page = route.data.userMembershipsData ? route.data.userMembershipsData.page : true;
        return this.userService
        .getUserGroupMemberships({ limit: page ? hypegraphConfig
        .limit : 0, offset: 0 })
        .pipe(
            take(1),
            map((res) => res.result),
        );
    }

}

