import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserService } from './users.service';
import { StateService } from './state.service';
import { FiltersService } from './filters.service';
import { PostsService } from './posts.service';
import { GroupsService } from './groups.service';
import { MsgComService } from './messageComment.service';
import { ToastrService} from 'ngx-toastr' ;
const SERVICES = [
  UserService,
  StateService,
  FiltersService,
  PostsService,
  GroupsService,
  MsgComService,
  ToastrService,
  
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ...SERVICES,
  ],
})
export class DataModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: DataModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
