import { Injectable } from '@angular/core';
import { Category, Tag } from '../models/filters.model';

@Injectable()
export class FiltersService {
    getCategories(): Category[] {
        return [
            {
                id: '1',
                name: 'France',
                desc: 'France',
            },
            {
                id: '2',
                name: 'Espagne',
                desc: 'Espagne',
            },
            {
                id: '3',
                name: 'Portugal',
                desc: 'Portugal',
            }, 
            {
                id: '4',
                name: 'Croatie',
                desc: 'Croatie',
            }, 
            {
                id: '5',
                name: 'Turquie',
                desc: 'Turquie',
            },
             
            {
                id: '6',
                name: 'Maroc',
                desc: 'Maroc',
            }, 
            {
                id: '7',
                name: 'Senegal',
                desc: 'Senegal',
            },
             
            {
                id: '8',
                name: "Cote_d_ivoire",
                desc: "Cote d'ivoire",
            },
             
            {
                id: '9',
                name: 'Nigeria',
                desc: 'Nigeria',
            },
            {
                id: '10',
                name: 'Usa',
                desc: 'Usa',
            },



        ];
    }
    getServicesTags(): Tag[] {
        return [
            {
                id: '1',
                name: 'construire',
                desc: 'Construire',
            },
            {
                id: '2',
                name: 'renover',
                desc: 'Rénover',
            },
            {
                id: '3',
                name: 'suivre',
                desc: 'Suivre',
            },
        ]}
    getTags(): Tag[] {
        return [
            {
                id: '1',
                name: 'Terrain_a_construire',
                desc: 'Terrain à construire',
            },
            {
                id: '2',
                name: 'Projets_en_cours_Vefa',
                desc: 'Projets en cours Vefa',
            },
            {
                id: '3',
                name: 'Appartement_a_renover',
                desc: 'Appartement à renover'
            }, 
            {
                id: '4',
                name: 'Ventes_privee',
                desc: 'Ventes privée',
            },
            {
                id: '5',
                name: "Trouver_l_inspiration",
                desc: "Trouver l'inspiration",
            },
            {
                id: '6',
                name: "A_vendre",
                desc: "A vendre",
            },
            {
                id: '8',
                name: "Deja_vendu",
                desc: "Déjà vendu",
            },
            {
                id: '9',
                name: "Titre_foncier",
                desc: "Titre foncier",
            },
            {
                id: '10',
                name: "CCMI",
                desc: "CCMI",
            },
        ];
    }

    getFilters() {
        return [
            {
                id: '1',
                name: 'Most Popular',
            },
            {
                id: '2',
                name: 'Last Week',
            },
            {
                id: '3',
                name: 'Last Month',
            },
        ];
    }
    getAccess() {
        return [
            {
                id: '0',
                name: 'Privée',
                uid:'me0'
            }, 
            
        ];
    }
    getAccessAdmin() {
        return [
            {
                id: '0',
                name: 'Privée',
                uid:'me0'
            },
            {
                id: '1',
                name: 'Utilisateurs connectés',
                uid:'me1'
            },
            
            {
                id: '2',
                name: 'Utilisateurs non connectés',
                uid:'me2'
            },
            {
                id: '-2',
                name: 'Mes amis',
                uid:'me3'
            },
            
        ];
    }
  

}

