import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// import { getDeepFromObject } from '@nebular/auth/helpers'
import { User } from '../../../../@core/models/user.model';
import { generalConfig } from '../../../../../general-config';
import { UserService } from '../../../../@core/data/users.service';


@Component({
  selector: 'ngx-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {

  formData: { user: User, terms: boolean, confirmPass: string }
  user: User;
  isSubmitting = false;
  errors: string[] = [];
  socialLinks = [];


  constructor(
    private router: Router,
    private userService: UserService,
  ) {

  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.formData = { user: {}, confirmPass: '', terms: false };
    this.socialLinks = generalConfig.socialLinks;
    this.formData.user.username_p = '';
    this.formData.user.form_p = false;
  }


  onSubmit(): void {
    this.isSubmitting = true;
    this.errors = [];
    this.register();
  }


  register(): void {
    // console.log(this.formData.user)
    this.userService.register(this.formData.user)
      .subscribe(
        () => {
          // this.isSubmitting = false;
          // this.userService.setNewUserFlag();
          // this.router.navigate(['/auth/login']);
          this.login(this.formData)
        },
        errRes => {
          this.isSubmitting = false;
          if (errRes.error.message) {
            this.errors.push(errRes.error.message);
          } else {
            this.errors.push(generalConfig.errorMsg);

          }
        },
    );
  }
login(passedData){
  this.userService.login(passedData.user)
  .subscribe(
    (res:any) => { 
      this.userService.setUserData(res)
      })
}
 


  getGeneralConfigValue(key: string): any {
    // return getDeepFromObject(generalConfig, key, null);
  }

}




