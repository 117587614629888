import { Component, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../../../@core/models/user.model';
import { FollowChangeEvent } from '../../../@core/models/Events.model';

@Component({
  selector: 'ngx-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  @Input() user: User;
  @Input() current: boolean; 
  @Output() followChange: EventEmitter<FollowChangeEvent> = new EventEmitter();


  onFollowClick() {
    this.followChange.emit({ user: this.user });
  }

}
