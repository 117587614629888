import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserService } from '../../../@core/data/users.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { User } from '../../../@core/models/user.model';
import {FeatherService} from '../../../@core/utils/feather.service'
import {modalService_data} from '../../../@core/utils/modal.service'
import { ModaldetailsComponent } from '../details-modal/details-modal.component';
import { ProfileMenuService } from '../../../@core/utils/profile-menu.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as introJs from 'intro.js/intro.js';
import { Router , Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {


  @Input() position = 'normal';

  user: User;
  token : String;

  userMenu = [{ id: 1, title: 'Compte' }, { id: 2, title: 'Déconnection' }];

  isAlive: boolean = true;
  introJS = introJs();
  helpBtn: boolean= false;

  constructor(
    private ProfileMenuService: ProfileMenuService,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private featherService:  FeatherService,
    private modalService_data : modalService_data, 
    private modalService: NgbModal,
      private router: Router 
  ) {
    this.router.events.subscribe((event: Event) => { 
      if (event instanceof NavigationEnd) {

          setTimeout(() => {
   //#tutorial
              if (window.location.href.toString().lastIndexOf('explore') > 0
                   || window.location.href.toString().lastIndexOf('cao') > 0
                   || window.location.href.toString().lastIndexOf('profile') > 0
                   || window.location.href.toString().lastIndexOf('prodashboard') > 0
                   || window.location.href.toString().lastIndexOf('paiement') > 0 
                   ){
                       this.helpBtn = true;  
                       //handle first user connection 
                       if(window.location.href.toString().lastIndexOf('demo') > 0 && this.userService.getCurrentUser().briefdescription=="Entreprise" )
                       { 
                        this.startIntro()
                       }
              }else{
                       this.helpBtn = false;
              } 
          }, 200);

      }})
     
  }

  ngOnInit() {
    this.init();
   this.menuService.onItemClick().subscribe(() => { }) 
   this.ProfileMenuService.init()
   
  }


  init() {
    this.user = this.userService.getCurrentUser();
    this.token = this.userService.getToken(); 
  //  setTimeout(() => {
  //    if(window.location.href.toString().lastIndexOf('demo') > 0 && this.userService.getCurrentUser().briefdescription=="Entreprise" )
  //     { 
  //      this.startIntro( )
  //     } 
  // }, 1000);
 
  }

  startIntro( ){   
    let user_type

if(this.userService.getCurrentUser()){
  user_type = this.userService.getCurrentUser().briefdescription
}else{
    user_type = "Demo"
}

     if ( window.location.href.toString().lastIndexOf('explore') > 0){

      this.introJS.setOptions({
        nextLabel:"Suivant",
        prevLabel:"Précedent", 
        skipLabel: "Annuler", 
        doneLabel: "Page suivante",
        tooltipPosition: 'auto',
        scrollToElement:true,
        steps: [
          {
            element:  document.querySelectorAll('.post-container')[1],
            intro: "Voir une annonce  ",
            position: 'top'
          },
          {
            element: document.querySelectorAll('.post-container nb-card-header')[0],
            intro: "Le profile de l'annonceur ",
            position: 'left'
          } ,
           
          {
            element: document.querySelectorAll('.post-container .first_page')[0],
            intro: "Les indicateurs de l'annonce",
            position: 'top'
          },
          
        ]
      });

        this.introJS.start().oncomplete(() => {
          //#tutorial may be buggy if this does not exist 
        window.location.href = '#/pages/cao/view/oe227?demo=true';
      });

     
    }
 
    if (  window.location.href.toString().lastIndexOf('cao') > 0){
      //find demo true is header 
      if(document.querySelector('.cao-rapport-containt') === null){
        let el =  <HTMLElement>document.querySelectorAll('.cao_bottom_buttons_inner .bg-primary')[1];
      el.click();
      }
        
      
      setTimeout(() => {
      this.introJS.setOptions({
        nextLabel:"Suivant",
        prevLabel:"Précedent", 
        skipLabel: "Annuler", 
        doneLabel: "Page suivante", 
        tooltipPosition: 'auto',
        scrollToElement:true,
        steps: [ 
          {
            element:  document.querySelectorAll('.cao_bottom_buttons_inner .bg-primary')[1],
            intro: "Afficher les pages",
            position: 'left'
          }, 
          {
            element:  document.querySelector('.cao-rapport-containt'),
            intro: "Editer une page",
            position: 'left'
          }, 
          {
            element: document.querySelectorAll('#help_edit ul li')[1],
            intro: "Ajouter des éléments d'analyse",
            position: 'left'
          },
          
          {
            element: document.querySelectorAll('.cao_bottom_buttons_inner .bg-primary')[0],
            intro: "Editer les éléments de la  3D",
            position: 'right'
          },
          
          
        ]
      });
      // this.introJS.start().onchange(function(targetElement) { 
      //   if ( this.currentStep() == "2") { 

      //    }

      // });

      this.introJS.start().oncomplete(() => {
            if (user_type== "Entreprise" ){
                    window.location.href = '#/pages/profile?demo=true';

            }
       
      });

    }, 2000);
      ;
    }
    
    if (user_type== "Entreprise"){

      if(window.location.href.toString().lastIndexOf('profile') > 0
      || window.location.href.toString().lastIndexOf('prodashboard') > 0
      || window.location.href.toString().lastIndexOf('paiement') > 0 ){
        window.location.href = '#/pages/prodashboard';  
     
      
      setTimeout(() => {
      this.introJS.setOptions({
        nextLabel:"Suivant",
        prevLabel:"Précedent", 
        skipLabel: "Annuler", 
        doneLabel: "Terminer", 
        tooltipPosition: 'auto',
        scrollToElement:true,
        steps: [  
          {
            element:  document.querySelector('#help_user_btn'),
            intro: "Administrez votre compte et analysez vos prospects ici",
            position: 'left'
          },  
        ]
      });
       
      this.introJS.start().oncomplete(() => { 
        window.location.href = '#/pages/prodashboard';
      });
    }, 1000);
      ;
    }
   }

    }


  toggleSidebar(): boolean {
    this.sidebarService.toggle(false, 'menu-sidebar');

    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'filters-sidebar');
    return false;
  }

  goToHome() {
    // window.location.href = 'https://www.caza3d.com/';
    // window.open('https://www.caza3d.com/','_blank');
    //this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  ngOnDestroy() {
    this.isAlive = false;
  }
  openCard(){

    var activeModal = this.modalService.open(ModaldetailsComponent, { size: 'lg', container: 'nb-layout' });
    this.modalService_data.clean();
//simply get available prod and services 
if(this.modalService_data.basket == undefined){
 this.modalService_data.basket = [];
}

this.modalService_data.headtitle = "Panier";
this.modalService_data.content_text = "Valider achat"; 
this.modalService_data.btn_annuler ="Annuler";
this.modalService_data.btn_oui ="Oui";  
this.modalService_data.show_paybutton = true
this.featherService.get_card_total().subscribe((res:any)=> { 
this.modalService_data.card_booked_total = res.data[0]
})

this.featherService.get_card_booked().subscribe((res:any)=>{ 
 this.modalService_data.basket = res.data[0] ;
}) 
  }
}
