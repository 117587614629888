import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { UserService } from '../data/users.service';

@Injectable()
export class AdminGuard implements CanActivate, CanLoad {
    constructor(
        private router: Router,
        private userService: UserService,
    ) {

    }
    canActivate() {
        return this.checkIsAdmin();
    }

    canLoad() {
        return this.checkIsAdmin();
    }

    checkIsAdmin() {
        const isAdmin = this.userService.isAdmin();
        if (!isAdmin) {
            this.router.navigate(['/pages/home']);
        }
        return isAdmin;
    }
}
