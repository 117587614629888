import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from '../../../../node_modules/rxjs';
import { take, map } from '../../../../node_modules/rxjs/operators';
import { UserService } from '../data/users.service';
import { hypegraphConfig } from '../../../api-config';

@Injectable()
export class UserLikesResolver implements Resolve<Observable<any>> {

    constructor(private userService: UserService) { }

    resolve() {
        return this.userService
        .getUserLikes({ limit: hypegraphConfig.limit , offset: 0 })
        .pipe(
            take(1),
            map((res) => res.result),
        );
    }

}

