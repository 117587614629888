import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { takeWhile, filter } from 'rxjs/operators';
import { 
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
  NbSearchService,
} from '@nebular/theme';

import { StateService } from '../../../@core/data/state.service';
import { UserService } from '../../../@core/data/users.service';
// import { Tag, Filter, Category, FiltersState } from '../../../@core/models/filters.model';

// TODO: move layouts into the framework
import {modalService_data} from '../../../@core/utils/modal.service'
@Component({
  selector: 'ngx-sample-layout',
  styleUrls: ['./sample.layout.scss'],
  templateUrl: './sample.layout.html',
})
export class SampleLayoutComponent implements OnInit, OnDestroy {

  layout: any = {};
  sidebar: any = {};
  currentTheme: string;
  // tags: Tag[];
  // categories: Category[];
  // filters: Filter[];
  filtersState = 'expanded';
  MenuState = 'collapsed';
  // currentFilters: FiltersState = {};
  private alive = true; 
  with_footer: boolean =false;


  constructor(
    protected searchService: NbSearchService,
    protected menuService: NbMenuService,
    protected themeService: NbThemeService,
    protected bpService: NbMediaBreakpointsService,
    protected sidebarService: NbSidebarService,
    protected UserService: UserService,
    protected stateService: StateService,
    protected router: Router,
    private modalService_data : modalService_data, 
  ) {

  }


  ngOnInit() {
    this.init();
    this.initSubscriptions(); 
  }

  init() {
 
    if(this.router.url.toString().lastIndexOf('cao') > 0) this.with_footer= false
    // this.tags = this.filtersService.getTags();
    // this.categories = this.filtersService.getCategories();
    // this.filters = this.filtersService.getFilters();

    
this.router.events
.pipe(filter(event => event instanceof NavigationEnd))
.subscribe( (event: NavigationEnd)  => {  
this.sidebarService.toggle(false, 'menu-sidebar');
this.sidebarService.collapse('menu-sidebar');
// this.setSidebarState();
})
  }


  initSubscriptions() {

    this.stateService.onLayoutState()
      .pipe(takeWhile(() => this.alive))
      .subscribe((layout: string) => this.layout = layout);

    this.stateService.onSidebarState()
      .pipe(takeWhile(() => this.alive))
      .subscribe((sidebar: string) => {
        this.sidebar = sidebar;
      });

   
    this.themeService.onMediaQueryChange().subscribe(([item, [bpFrom, bpTo]]: [any, ["", ""]]) => { 
    });
//remove this to avoid boutton resize problem 
/*
const isBp = this.bpService.getByWidth(800);
        this.menuService.onItemSelect()
      .pipe(
        takeWhile(() => this.alive),
        withLatestFrom(this.themeService.onMediaQueryChange()),
        delay(20),
    )
      .subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint , NbMediaBreakpoint]]) => {

        if (bpTo.width <= isBp.width) {
          this.sidebarService.collapse('menu-sidebar');
          console.log("should collapse menu ")
        }
      });
*/
    this.themeService.getJsTheme()
      .pipe(takeWhile(() => this.alive))
      .subscribe(theme => {
        this.currentTheme = theme.name;
      });

    this.searchService
      .onSearchSubmit()
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        data => {
          // this.currentFilters.search = data.term;
          // this.applyFilters();
        },
    );
    this.setSidebarState();
      
  }

  setSidebarState() {
    this.filtersState = window.location.href.indexOf('home') === -1 ? 'collapsed' : 'collapsed';
    console.log( this.filtersState)
	   this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(
        (event: NavigationEnd) => {
          this.filtersState = event.url.indexOf('home') === -1 ? 'collapsed' : 'collapsed';
        
        },
    );
  }
close_menu(){ 
  this.sidebarService.toggle(false, 'menu-sidebar');
}
  // onFiltersChanged(filtersState: FiltersState) {
  //   this.currentFilters = { ...this.currentFilters, ...filtersState };
  //   this.applyFilters();
  // }
   
  

  // applyFilters() {
  //   this.router.navigate(['pages/explore'], { queryParams: this.currentFilters })

  // }
 
  ngOnDestroy() {
    this.alive = false;
  }
  logout(){
    this.UserService.logout()
    this.router.navigate(['/auth/login']);
  }
}
