import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { feather,template_sig, vr_tour_prdt } from '../../../api-config';

import { v4 as uuid } from 'uuid'

import { UserService } from '../data/users.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatherService {
  modeledData: any
  constructor(private httpClient: HttpClient, private userService: UserService,) {

  }
  public generate_data_from_url() {  
   return this.httpClient.get(`https://vrtour.caza3d.com/image-reference/`) 
  }
  public loadedData() {
    var token_info = this.userService.getToken();
    return this.httpClient.get(`${feather
      .base}/prodtemp?token=` + token_info + `&$skip=0`);
  }
  public load_unique_Data(skip: Number, type: string) {
    var token_info = this.userService.getToken();
    return this.httpClient.get(`${feather
      .base}/prodtemp?token=` + token_info + `&$skip=` + skip.toString() + `&type=` + type);
  }
  public load_unique_Sig(main:boolean,ext_name?:string, location?: string ,item_type? :string,c_lat?,c_long?,also?,id?) {
    //  http://localhost:6060/sig-extern/?ext_name=api_default&c_lat=48.85534106071708&c_long=2.3248100280761723&c_area=4575&c_z_geo=1000&commune=74450&also=VenteImmo&id=4548-87dd-88
   let url_param=""
   if(main){
    url_param="?status=published"
   }else{
    url_param="?ext_name="+ext_name+"&item_type="+item_type+"&c_lat="+c_lat+"&c_long="+c_long+"3&c_area=4575&c_z_geo=1000&commune="+location+"&also="+also+"&id="+id
   }
    return this.httpClient.get(`${template_sig
      .base}/sig-extern/` + url_param);
  }
  public load_blob_img_list_by_owner_og(skip: Number, type: string,unik:string) {
   
    
    var token_info = this.userService.getToken();
    if (skip == 0) {
      return this.httpClient.get(`${feather
        .base}/image-reference/?token=` + token_info + `&status=published&item_type=image` + unik);
    } else {
      return this.httpClient.get(`${feather
        .base}/image-reference/?token=` + token_info + `&item_type=image&status=published&$skip=` + skip.toString() + unik);
    }
  }

  public load_blob_img_list_by_owner(skip: Number, type: string,unik:string) {
   
    var token_info = this.userService.getToken();
    if (skip == 0) {
      return this.httpClient.get(`${vr_tour_prdt
        .base}/image-reference-gltf/?token=` + token_info + `&status=published&item_type=`+type + unik);
    } else {
      return this.httpClient.get(`${vr_tour_prdt
        .base}/image-reference-gltf/?token=` + token_info + `&item_type=`+type+`&status=published&$skip=` + skip.toString() + unik);
    }
  }
  public load_blob_img_list_by_owner_gltf(skip: Number, type: string,unik:string) {
   
    var token_info = this.userService.getToken();
    if (skip == 0) {
      return this.httpClient.get(`${vr_tour_prdt
        .base}/mesh-reference-gltf/?token=` + token_info + `&status=published&item_type=gltf` + unik);
    } else {
      return this.httpClient.get(`${vr_tour_prdt
        .base}/mesh-reference-gltf/?token=` + token_info + `&item_type=gltf&status=published&$skip=` + skip.toString() + unik);
    }
  }
  public get_unique_img_ref(id) { 
    var token_info = this.userService.getToken();
    return this.httpClient.get(`${feather
      .base}/image-reference/` + id + `/?token=` + token_info);
  }
  public get_unique_img_ref_gltf(id) { 
    var token_info = this.userService.getToken();
    return this.httpClient.get(`${vr_tour_prdt
      .base}/image-reference-gltf/` + id + `/?token=` + token_info);
  }
  public get_unique_ref_gltf(id) { 
    var token_info = this.userService.getToken();
    return this.httpClient.get(`${vr_tour_prdt
      .base}/mesh-reference-gltf/` + id + `/?token=` + token_info);
  }
  public update_reference_gltf(data ,id) {
    var token_info = this.userService.getToken();
    return this.httpClient.patch(`${vr_tour_prdt
      .base}/mesh-reference-gltf/`+id+`/?token=` + token_info, data);
  }
  public create_blob_img(dataToPost, post_id,format) {
    
    var user_uid = this.userService.getCurrentUser().uid
    user_uid = user_uid.split('e')[1]
    this.modeledData = {
      "owner": user_uid,
      "item_type": "image",
      "filename" : format,
      "official": "no",
      "status": "published",
      "post_id_src": post_id,
      "uri": dataToPost
    }

    var token_info = this.userService.getToken();
    return this.httpClient.post(`${feather
      .base}/upload-images/?token=` + token_info, this.modeledData);
  }
  public create_blob_img_gltf(dataToPost, post_id,format,type) {
    
    var user_uid = this.userService.getCurrentUser().uid
    user_uid = user_uid.split('e')[1]
    this.modeledData = {
      "owner": user_uid,
      "item_type": type,
      "filename" : format,
      "official": "no",
      "status": "published",
      "gltf_id_src": post_id,
      "uri": dataToPost
    }

    var token_info = this.userService.getToken();
    return this.httpClient.post(`${vr_tour_prdt
      .base}/upload-images-gltf/?token=` + token_info, this.modeledData);
  }
  public create_blob_gltf(dataToPost, post_id,format,obj_id) {
    
    var user_uid = this.userService.getCurrentUser().uid
    user_uid = user_uid.split('e')[1]
    this.modeledData = {
      "owner": user_uid,
      "item_type": "gltf",
      "filename" : 'gltf',
      "official": "no",
      "status": "published",
      "gltf_id_node": obj_id,
      "post_id":post_id,
      "uri": dataToPost
    }

    var token_info = this.userService.getToken();
    return this.httpClient.post(`${vr_tour_prdt
      .base}/upload-mesh-gltf/?token=` + token_info, this.modeledData);
  }
 
  public create_blob_story_img_vid(dataToPost, post_id) {
    var user_uid = this.userService.getCurrentUser().uid
    user_uid = user_uid.split('e')[1]
    this.modeledData = {
      "owner": user_uid,
      "item_type": "story_image",
      "official": "no",
      "status": "published",
      "uri": dataToPost,
      "post_id_src": post_id
    }

    var token_info = this.userService.getToken();
    return this.httpClient.post(`${feather
      .base}/upload-images/?token=` + token_info, this.modeledData);
  }
  public create_blob_story_img_vid_only(dataToPost, post_id) {
    var user_uid = this.userService.getCurrentUser().uid
    user_uid = user_uid.split('e')[1]
    this.modeledData = {
      "owner": user_uid,
      "item_type": "story_vid",
      "official": "no",
      "status": "published",
      "uri": dataToPost,
      "post_id_src": post_id
    }

    var token_info = this.userService.getToken();
    return this.httpClient.post(`${feather
      .base}/upload-vid/?token=` + token_info, this.modeledData);
  }
  public update_blob_img_ref(dataID, data) {

    var token_info = this.userService.getToken();
    return this.httpClient.patch(`${feather
      .base}/image-reference/`+dataID+`/?token=` + token_info, data);
  }



  public get_unique_index_vrtour(id) {
    var token_info = this.userService.getToken();
    return this.httpClient.get(`${feather
      .base}/vrtour/?token=` + token_info + `&post_id=` + id);
  }

  public create_index_vrtour(dataToPost) {
    var user_uid = this.userService.getCurrentUser().uid
    user_uid = user_uid.split('e')[1] 

    var token_info = this.userService.getToken();
    return this.httpClient.post(`${feather
      .base}/vrtour/?token=` + token_info, dataToPost);
  }

  public update_index_vrtour(data ,id) {
    var token_info = this.userService.getToken();
    return this.httpClient.patch(`${feather
      .base}/vrtour/`+id+`/?token=` + token_info, data);
  }

  public get_story_images_attached_to_this_post(id) {
    // &status=published&post_id_src=oe175&item_type=story_image
    var token_info = this.userService.getToken();
    return this.httpClient.get(`${feather
      .base}/image-reference/?token=` + token_info + `&status=published&item_type=story_image&post_id_src=` + id);
  }
  public get_story_vid_attached_to_this_post(id) { 
    var token_info = this.userService.getToken();
    return this.httpClient.get(`${feather
      .base}/vid-reference/?token=` + token_info + `&status=published&item_type=story_vid&post_id_src=` + id);
  }
  public create_blob_story_html_amp(dataToPost) {
    var user_uid = this.userService.getCurrentUser().uid
    user_uid = user_uid.split('e')[1]
    this.modeledData = {
      "owner": user_uid,
      "item_type": "story_html",
      "official": "no",
      "status": "published",
      "uri": dataToPost
    }

    var token_info = this.userService.getToken();
    return this.httpClient.post(`${feather
      .base}/upload-html/?token=` + token_info, this.modeledData);
  }

  public get_unique_index_story(id) {
    var token_info = this.userService.getToken();
    return this.httpClient.get(`${feather
      .base}/story/?token=` + token_info + `&post_id=` + id);
  }
  public update_index_story(data,id) {
    var token_info = this.userService.getToken();
    return this.httpClient.patch(`${feather
      .base}/story/`+id+`/?token=` + token_info, data);
  }

  public create_index_story(dataToPost) {
    var user_uid = this.userService.getCurrentUser().uid
    user_uid = user_uid.split('e')[1]  
    var token_info = this.userService.getToken();
    return this.httpClient.post(`${feather
      .base}/story/?token=` + token_info, dataToPost);
  }

  public create_card(uid?: string) {
    var token_info = this.userService.getToken();
    uid = uid.split('e')[1]
    return this.httpClient.get(`${feather
      .base}/prodpay?token=` + token_info + `&action=first_connection&sublist=` + uid);
  }
  // public first_connection_data(uid?: string) {
  //   var token_info = this.userService.getToken();
  //   uid = uid.split('e')[1]
  //   var body = ''
  //   return this.httpClient.post(`${feather
  //     .base}/prodtemp?token=` + token_info + `&template=` + uid + `&skip=0&type=first_connection`, body);
  // }

  public loadedData_offer() {

    var token_info = this.userService.getToken();
    return this.httpClient.get(`${feather
      .base}/prodpay?token=` + token_info + `&action=GofferList`);
  }
  public get_default_card() {

    var token_info = this.userService.getToken();
    return this.httpClient.get(`${feather
      .base}/prodpay?token=` + token_info + `&action=Udefaultcard`);

  }
  public get_card_total() {

    var token_info = this.userService.getToken();
    return this.httpClient.get(`${feather
      .base}/prodpay?token=` + token_info + `&action=get_card_total`);
  }
  public modifCard(stripe_token: any, email: any, cardAndPayTotal?: any, stripe_card?: any) {

    var token_info = this.userService.getToken();
    if (cardAndPayTotal) {
      return this.httpClient.get(`${feather
        .base}/prodpay?token=` + token_info + `&action=Upaynow&defcardid=` + stripe_token + `&sublist=` + email + `&stripcard=` + stripe_card);

    } else {

      return this.httpClient.get(`${feather
        .base}/prodpay?token=` + token_info + `&action=modifCard&defcardid=` + stripe_token + `&sublist=` + email + `&stripcard=` + stripe_card);

    }

  }

  public paynow_booked(list: any, StripeCardID?: any) {

    var token_info = this.userService.getToken();
    return this.httpClient.get(`${feather
      .base}/prodpay?token=` + token_info + `&action=Upaynow&sublist=` + list);
  }

  public get_card_booked() {

    var token_info = this.userService.getToken();

    return this.httpClient.get(`${feather
      .base}/prodpay?token=` + token_info + `&action=get_card_booked`);
  }
  public get_card_active() {

    var token_info = this.userService.getToken();

    return this.httpClient.get(`${feather
      .base}/prodpay?token=` + token_info + `&action=get_card_active`);
  }

  public update_booked(list: any) {

    var token_info = this.userService.getToken();

    return this.httpClient.get(`${feather
      .base}/prodpay?token=` + token_info + `&action=Uservicesub&sublist=` + list);
  }

  public remove_booked(list: any) {

    var token_info = this.userService.getToken();

    return this.httpClient.get(`${feather
      .base}/prodpay?token=` + token_info + `&action=RemoveBooked&sublist=` + list);
  }

  public remove_card() {

    var token_info = this.userService.getToken();

    return this.httpClient.get(`${feather
      .base}/prodpay?token=` + token_info + `&action=Uremovecard`);
  }
  public deleteData(dataID) {

    var token_info = this.userService.getToken();

    let url = feather.base + "/prodtemp?token=" + token_info + "&del=" + dataID;
    return this.httpClient.get(url);
  }



  public paynow_bill(list: any) {

    var token_info = this.userService.getToken();

    return this.httpClient.get(`${feather
      .base}/prodpay?token=` + token_info + `&action=Uservicesub&sublist=` + list);
  }
  public postData(dataToPost, params?: String, filename?: String) {
    var user_uid = this.userService.getCurrentUser().uid
    user_uid = user_uid.split('e')[1]
    switch (params) {
      case "image":
        this.modeledData = {
          "owner": user_uid,
          "item_type": "unique_img",
          "official": "no",
          "unique_content": {
            "sub_type": "data_img",
            "main_img": dataToPost,
            "location": [{ "posY": "45", "width": "45", "height": "78" }],
            "data": [{ "base": dataToPost }]
          }
        }
        break;

      case "video":

        this.modeledData = {
          "owner": user_uid,
          "item_type": "unique_vid",
          "official": "no",
          "unique_content": {
            "sub_type": "data_vid",
            "main_img": "default",
            "location": [{ "posY": "45", "width": "45", "height": "78" }],
            "data": [{ "base": dataToPost }]
          }
        }
        break;
      case "csvData":

        this.modeledData = {
          "owner": user_uid,
          "item_type": "unique_data",
          "official": "no",
          "unique_content": {
            "sub_type": "data_graph",
            "main_img": "default",
            "filename": filename,
            "location": [{ "posY": "45", "width": "45", "height": "78" }],
            "data": [{ "base": dataToPost }]
          }
        }
        break;
      default:

        break;
    }

    var token_info = this.userService.getToken();
    //token_info ="5454"
    return this.httpClient.post(`${feather
      .base}/template?token=` + token_info + `&template=` + user_uid, this.modeledData);
  }

  public get_Html_asset() {
    return this.httpClient.get(`./assets/html/index.json`);
  }
  public get_storie_asset() {
    return this.httpClient.get('./assets/html/storie.html', { responseType: "text" })
  }
   


  public mapApiCall(Slat: Number, Slong: Number, Nlat: Number, Nlong: Number, type?: string): Observable<any> {
    //docu : node = lat lon 
    //way = define an area
    //relation = relate area  
    // ,,49.0205264,17.160776
    // Slat=46.3722761
    // Slong=9.5307487
    // Nlat=49.0205264
    // Nlong=17.160776
    // type= 'cafe';

    var body = 'https://overpass-api.de/api/interpreter?data=[out:json][timeout:25];(node["amenity"="' + type + '"](' + Slat + ',' + Slong + ',' + Nlat + ',' + Nlong + ');way["amenity"="' + type + '"](' + Slat + ',' + Slong + ',' + Nlat + ',' + Nlong + ');relation["amenity"="' + type + '"](' + Slat + ',' + Slong + ',' + Nlat + ',' + Nlong + '););out%20meta;'
    // https://overpass-api.de/api/interpreter?data=[out:json][timeout:25];(node[%22amenity%22=%22shop%22](46.3722761,9.5307487,49.0205264,17.160776);way[%22amenity%22=%22shop%22](46.3722761,9.5307487,49.0205264,17.160776);relation[%22amenity%22=%22shop%22](46.3722761,9.5307487,49.0205264,17.160776););out%20meta;
    return this.httpClient.get<any>(body);

  }

  public mapApiCall_ods_sport(Clat: Number, Clong: Number, area: Number, type?: string): Observable<any> {

    type = 'buildingref-france-bpe-all-geolocated'
    var body = 'https://public.opendatasoft.com/api/records/1.0/search/?dataset=' + type + '&q=&sort=year&facet=equipment_name&facet=category&facet=epci_name&refine.category=Sports%2C+loisirs+et+culture&geofilter.distance=' + Clat + '%2C' + Clong + '%2C' + area
    //var body = 'https://public.opendatasoft.com/api/records/1.0/search/?dataset='+type+'&q=25&start=0&sort=equdatecreation&geofilter.distance='+Clat+'%2C'+Clong+'%2C'+area+'&facet=comlib&facet=equipementtypelib&facet=gestiontypeproprietaireprinclib&facet=naturesollib&facet=naturelibelle&facet=equacceshandimaire&facet=caracteristiques'
    //https://public.opendatasoft.com/api/records/1.0/search/?dataset=buildingref-france-bpe-all-geolocated&q=&sort=year&facet=equipment_name&facet=category&facet=epci_name&refine.category=Sports%2C+loisirs+et+culture
    return this.httpClient.get<any>(body);

  }

  public mapApiCall_ods_venteimmo(Clat: Number, Clong: Number, area: Number, type?: string): Observable<any> {

    type = 'demande-de-valeurs-foncieres'
    var body = 'https://public.opendatasoft.com/api/records/1.0/search/?dataset=' + type + '&q=&rows=20&sort=date_mutation&facet=date_mutation&facet=nature_mutation&facet=commune&facet=section&facet=nombre_de_lots&facet=type_local&facet=nature_culture&facet=nom_reg&facet=nom_dep&facet=nom_epci&facet=libelle_nature_culture_speciale&geofilter.distance=' + Clat + '%2C' + Clong + '%2C' + area
    //https://public.opendatasoft.com/api/records/1.0/search/?dataset=demande-de-valeurs-foncieres&q=&rows=20&sort=date_mutation&facet=date_mutation&facet=nature_mutation&facet=commune&facet=section&facet=nombre_de_lots&facet=type_local&facet=nature_culture&facet=nom_reg&facet=nom_dep&facet=nom_epci&facet=libelle_nature_culture_speciale&geofilter.distance=48.8520930694%2C2.34738897685%2C1000
    return this.httpClient.get<any>(body);

  }
  public mapApiCall_ods_commerce(Clat: Number, Clong: Number, area: Number, type?: string): Observable<any> {

    type = 'buildingref-france-bpe-all-geolocated'
    var body = 'https://public.opendatasoft.com/api/records/1.0/search/?dataset=' + type + '&q=&sort=year&facet=equipment_name&facet=category&facet=epci_name&refine.category=Commerces&geofilter.distance=' + Clat + '%2C' + Clong + '%2C' + area
    // https://public.opendatasoft.com/api/records/1.0/search/?dataset=buildingref-france-bpe-all-geolocated&q=&sort=year&facet=equipment_name&facet=category&facet=epci_name&refine.category=Commerces
    return this.httpClient.get<any>(body);

  }

  public mapApiCall_ods_education(Clat: Number, Clong: Number, area: Number, type?: string): Observable<any> {

    type = 'buildingref-france-bpe-all-geolocated'
    var body = 'https://public.opendatasoft.com/api/records/1.0/search/?dataset=' + type + '&q=&sort=year&facet=equipment_name&facet=category&facet=epci_name&refine.category=Enseignement&geofilter.distance=' + Clat + '%2C' + Clong + '%2C' + area
    //https://public.opendatasoft.com/api/records/1.0/search/?dataset=buildingref-france-bpe-all-geolocated&q=&sort=year&facet=equipment_name&facet=category&facet=epci_name&refine.category=Enseignement
    return this.httpClient.get<any>(body);

  }

  public mapApiCall_ods_transport(Clat: Number, Clong: Number, area: Number, type?: string): Observable<any> {

    type = 'buildingref-france-bpe-all-geolocated'
    var body = 'https://public.opendatasoft.com/api/records/1.0/search/?dataset=' + type + '&q=&sort=year&facet=equipment_name&facet=category&facet=epci_name&refine.category=Transports+et+d%C3%A9placements&exclude.equipment_name=Taxi-VTC&geofilter.distance=' + Clat + '%2C' + Clong + '%2C' + area
    //https://public.opendatasoft.com/api/records/1.0/search/?dataset=buildingref-france-bpe-all-geolocated
    //&q=&sort=year&facet=equipment_name&facet=category&facet=epci_name&refine.category=Transports+et+d%C3%A9placements&exclude.equipment_name=Taxi-VTC 
    return this.httpClient.get<any>(body);

  }
  public mapApiCall_ods_airbnb(Clat: Number, Clong: Number, area: Number, type?: string): Observable<any> {

    type = 'airbnb-listings'
    // var body = 'https://public.opendatasoft.com/api/records/1.0/search/?dataset='+type+'&q=100&start=0&sort=equdatecreation&geofilter.distance='+Clat+'%2C'+Clong+'%2C'+area+'&facet=comlib&facet=equipementtypelib&facet=gestiontypeproprietaireprinclib&facet=naturesollib&facet=naturelibelle&facet=equacceshandimaire&facet=caracteristiques'
    // var body = 'https://public.opendatasoft.com/api/records/1.0/search/?dataset='+type+'&q=25&start=0&sort=equdatecreation&geofilter.distance='+Clat+'%2C'+Clong+'%2C'+area+'&facet=property_type&facet=room_type'

    var body = 'https://public.opendatasoft.com/api/records/1.0/search/?dataset=airbnb-listings&q=&rows=10&start=1&facet=property_type&facet=room_type&geofilter.distance=' + Clat + '%2C' + Clong + '%2C' + area
    return this.httpClient.get<any>(body);

  }

  public postcode_to_lat(postcode?: string): Observable<any> {
    var body = 'https://api-adresse.data.gouv.fr/search/?q=' + postcode + '&type=municipality'     
    return this.httpClient.get<any>(body);
  }




}
