import { Injectable } from '@angular/core';
import { Post, PostsResponse } from '../models/post.model';
import { Observable } from '../../../../node_modules/rxjs';
import { HttpClient, HttpParams } from '../../../../node_modules/@angular/common/http';
import { hypegraphConfig ,vr_tour_prdt} from '../../../api-config';
import { Md5 } from 'ts-md5/dist/md5'

import { v4 as uuid } from 'uuid'

import { UserService } from './users.service';


interface PostsFilteringParams {
    limit?: number,
    offset?: number,
    username?: string,
    targetUid?: string,
}

@Injectable()
export class PostsService {
    modeledData: any
    constructor(
        private http: HttpClient,
        private userService: UserService,
    ) { }
    public create_blob_gltf(dataToPost, post_id,format,obj_id,status:string="published") {
    
        var user_uid = this.userService.getCurrentUser().uid
        user_uid = user_uid.split('e')[1]
        this.modeledData = {
          "owner": user_uid,
          "item_type": "gltf",
          "filename" : format,
          "official": "no",
          "status": status,
          "gltf_id_node": obj_id,
          "post_id":post_id,
          "uri": dataToPost
        }
    
        var token_info = this.userService.getToken();
        return this.http.post(`${vr_tour_prdt
          .base}/upload-mesh-gltf/?token=` + token_info, this.modeledData);
      }
      
      public create_blob_img_gltf(dataToPost, post_id,item_type) {
    
    var user_uid = this.userService.getCurrentUser().uid
    user_uid = user_uid.split('e')[1]
    let modeledData = {
        "owner": user_uid,
        "item_type": item_type,
        "filename" : '',
        "official": "no",
        "status": "published",
        "gltf_id_src": post_id,
        "uri": dataToPost
      }
    var token_info = this.userService.getToken();
    return this.http.post(`${vr_tour_prdt
      .base}/upload-images-gltf/?token=` + token_info, modeledData);
  }
  
  public get_unique_img_ref_gltf(id) { 
    var token_info = this.userService.getToken();
    return this.http.get(`${vr_tour_prdt
      .base}/image-reference-gltf/` + id + `/?token=` + token_info);
  }
  public get_unique_ref_gltf(id) { 
    var token_info = this.userService.getToken();
    return this.http.get(`${vr_tour_prdt
      .base}/mesh-reference-gltf/` + id + `/?token=` + token_info);
  }

    createMimetype(extension: string) : string {
        switch (extension.toLowerCase()) {
            case 'gltf':
                return 'model/gltf+json'
            case 'bin':
                return 'application/octet-stream'
            case 'glb':
                return 'model/gltf-binary'
            case 'stp':
                return 'application/STEP'
            case 'step':
                return 'application/STEP'
            case 'jt':
                return 'application/x-jt'
        }
    }

    createChecksum(contents: string) : any {
        const md5 = new Md5()
        const hash = md5.appendStr(contents).end(false)
        return hash
    }

    createNewPost(post: Post, targetUid?: string): Observable<PostsResponse> {
        if (!post.mimetype) {
            const extension = post.filename.split('.').slice(-1)[0]
            post.mimetype = this.createMimetype(extension)
        }

        if (!post.checksum) {
            post.checksum = this.createChecksum(post.contents)
        }

        let formData = new FormData()
        // formData.append('filename', post.filename)
        if (post.chunk_id){
            formData.append('chunk_id', post.chunk_id)
            //is not used 
            formData.append('filename', 'chunk_'+ post.chunk_id  )
             //is not used 
        }else{
            formData.append('filename', `${uuid()}.${post.filename.split('.').slice(-1)[0]}`)
        }

        
        formData.append('contents', post.contents) //gltf chunk_id 
        // formData.append('chunk_me', 'true')
        // formData.append('chunk_id', post.id)
        formData.append('mimetype', post.mimetype)
        formData.append('checksum', post.checksum)

        if (post.icon_contents) {
            formData.append('icon_contents', post.icon_contents)
            formData.append('icon_mimetype', post.icon_mimetype)
            formData.append('icon_filename', post.icon_filename)
        }
        
           
        if (post.title) {
            formData.append('title', post.title)
        }
        if (post.description) {
            formData.append('description', post.description)
        }
        if (post.access_id) {
            formData.append('access_id', post.access_id.toString())
        }
        if (post.tagsObject) {
            post.tags = post.tagsObject.map(
                item => item.name,
            );
            formData.append('tags', post.tags.toString())
        }

        const target = targetUid || 'me'
        return this.http.post<any>(`${hypegraphConfig.base}/${target}/files`, formData);
    }

    createNewProfilePic(post: Post): Observable<PostsResponse> {
          
        if (!post.checksum) { post.checksum = this.createChecksum(post.icon_contents) }

        let formData = new FormData() 
        if (post.icon_contents) {
            formData.append('icon_contents', post.icon_contents)
            formData.append('icon_mimetype', post.icon_mimetype)
            formData.append('icon_filename', post.icon_filename)
            
            formData.append('avatar',  "avatar")
            
            formData.append('mimetype', 'default')
            formData.append('checksum', 'default')
            formData.append('filename', 'default')
            formData.append('contents', 'default')
        }  
        if (post.access_id) {
            formData.append('access_id', '2') //public but not used 
        } 

        if (post.foreign_owner_uid) { 
            formData.append('owner_guid', post.foreign_owner_uid.toString().split('e')[1]) 
        } 
        const target = 'me'
        return this.http.post<any>(`${hypegraphConfig.base}/${target}/files`, formData);
    }

    updatePost(post: Post, targetUid?: string ,contain ?: any, access_level ? : any  ): Observable<any> {

        if(targetUid=='me' || targetUid==''){
            if (!post.mimetype) {
                const extension = post.filename.split('.').slice(-1)[0]
                post.mimetype = this.createMimetype(extension)
            }
    
            if (!post.checksum) {
                if (post.contents)
                    post.checksum = this.createChecksum(post.contents)
            }
    
            let formData = new FormData() 
            if (post.chunk_id){
                    formData.append('chunk_id', post.chunk_id)
                     //is not used 
                    formData.append('filename', 'chunk_'+ post.chunk_id  )
                     //is not used 
            }else{
                    formData.append('filename', `${uuid()}.${post.filename.split('.').slice(-1)[0]}`)
            }

            if (post.mimetype)
                formData.append('mimetype', post.mimetype)
            // if (post.chunk_id)
            //     formData.append('chunk_id', post.chunk_id)
            if (post.checksum)
                formData.append('checksum', post.checksum)
            if (post.contents)
                formData.append('contents', post.contents)
    
            if (post.icon_contents) {
                formData.append('icon_contents', post.icon_contents)
                formData.append('icon_mimetype', post.icon_mimetype)
                formData.append('icon_filename', post.icon_filename)
            }
            
            if (post.title) {
                formData.append('title', post.title)
            }
            if (post.description) {
                formData.append('description', post.description)
            }
            
            if (post.tagsObject.length > 0 && post.tagsObject[0] != undefined ) {
                post.tags = post.tagsObject.map(
                    item => item.name,
                );
                formData.append('tags', post.tagsObject.toString())
            }
            if (post.access_id) {
                formData.append('access_id', post.access_id.toString())
            }
            if (post.foreign_owner_uid) { 
                formData.append('owner_guid', post.foreign_owner_uid.toString().split('e')[1])
                formData.append('container_guid', post.foreign_owner_uid.toString().split('e')[1])
            
            }

           
    console.log("post",post)
            return this.http.post<Post>(`${hypegraphConfig.base}/${post.uid}`, formData);
    //todo 
    //1 : container(user or group object  ) and access(group ou native ) need to by passed to the funtion 
    //2 : user the value on each if side 
    //3 : watch out geID is different to the real group ID .  
    //4
 

        }
        else{
            if (!post.mimetype) {
                const extension = post.filename.split('.').slice(-1)[0]
                post.mimetype = this.createMimetype(extension)
            }
    
            if (!post.checksum) {
                if (post.contents)
                    post.checksum = this.createChecksum(post.contents)
            }
    
            let formData = new FormData()
            if (post.filename)
                formData.append('filename', `${uuid()}.${post.filename.split('.').slice(-1)[0]}`)
                // formData.append('filename', post.filename)
            if (post.mimetype)
                formData.append('mimetype', post.mimetype)
            if (post.checksum)
                formData.append('checksum', post.checksum)
            if (post.contents)
                formData.append('contents', post.contents)
    
            if (post.icon_contents) {
                formData.append('icon_contents', post.icon_contents)
                formData.append('icon_mimetype', post.icon_mimetype)
                formData.append('icon_filename', post.icon_filename)
            }
            
            if (post.title) {
                formData.append('title', post.title)
            }
            if (post.description) {
                formData.append('description', post.description)
            }
            
            if (post.tagsObject.length > 0 && post.tagsObject[0] != undefined ) {
                post.tags = post.tagsObject.map(
                    item => item.name,
                );
                formData.append('tags', post.tagsObject.toString())
            }
            if (post.access_id) {
                formData.append('access_id', post.access_id.toString())
            }
            if(contain){
                formData.append('container_guid', contain)

            }
    
            return this.http.post<Post>(`${hypegraphConfig.base}/${post.uid}`, formData);
    

        }
        

    }

    getPost(uid: string): Observable<PostsResponse> {

        return this.http.get<PostsResponse>(`${hypegraphConfig.base}/${uid}`);
    }

    getLatestPosts(params: PostsFilteringParams,country? :string ,tags?: string,skills? :string ,interests?: string): Observable<PostsResponse> {
        let httpParams = new HttpParams();
         
        if (params.offset !== null && params.offset !== undefined) {
            httpParams = httpParams.set('offset', params.offset.toString());
        }
        if (params.limit !== null && params.limit !== undefined) {
            httpParams = httpParams.set('limit', params.limit.toString());
        }
        if (country) {
            httpParams = httpParams.set('country', country);
        }
        if (tags) {
            httpParams = httpParams.set('tags', tags);
        }
        if (skills) {
            httpParams = httpParams.set('skills', skills);
        }
        if (interests) {
          
            httpParams = httpParams.set('location', interests);
        }


        const targetUid = params.targetUid || hypegraphConfig.site;
        if (targetUid=='me'){
            //retreive private post
            var access = 1
            httpParams = httpParams.set('access',access.toString() );
        }
        return this.http.get<PostsResponse>(`${hypegraphConfig.base}/${targetUid}/files`
            , { params: httpParams });
    }
    getLatestBlog(params: PostsFilteringParams,category? :string ,tags?: string): Observable<PostsResponse> {
        let httpParams = new HttpParams();

        if (params.offset !== null && params.offset !== undefined) {
            httpParams = httpParams.set('offset', params.offset.toString());
        }
        if (params.limit !== null && params.limit !== undefined) {
            httpParams = httpParams.set('limit', params.limit.toString());
        }
        if (category) {
         //   httpParams = httpParams.set('category', category);
        }
        if (tags) {
            httpParams = httpParams.set('tags', tags);
        }

        const targetUid = params.targetUid || hypegraphConfig.site;
        return this.http.get<PostsResponse>(`${hypegraphConfig.base}/${targetUid}/blogs`
            , { params: httpParams });
    }

    getPostLikes(params: PostsFilteringParams, postID: string): Observable<any> {
        let httpParams = new HttpParams();

        if (params.offset !== null && params.offset !== undefined) {
            httpParams = httpParams.set('offset', params.offset.toString());
        }
        if (params.limit !== null && params.limit !== undefined) {
            httpParams = httpParams.set('limit', params.limit.toString());
        }

        return this.http.get(`${hypegraphConfig.base}/${postID}/likes`
            , { params: httpParams });
    }


    downloadPost(uid: string): void {
        window.location.href = `${hypegraphConfig.base}/${uid}?raw=true&auth_token=${this.userService.getToken()}`;
    }
//this realy download the 3D data todo split and improve 
    downloadPostUrl(uid: string ,chunk_id?:string,owner_guid?:string) : string {
        
         //chunk_id
        //154546-4564654.glb  
        
        if(chunk_id){
            var user_uid = owner_guid.split('e')[1]
 return `${hypegraphConfig.base}/${uid}?raw=true&chunk_id=${chunk_id}&owner_guid=${user_uid}&auth_token=${this.userService.getToken()}&api_key=${hypegraphConfig.publicKey}`;
        }else{
return `${hypegraphConfig.base}/${uid}?raw=true&auth_token=${this.userService.getToken()}&api_key=${hypegraphConfig.publicKey}`;
        }
        
       
        // 
    }
    downloadPostUrl_def(uid: string) : Observable<any> {
        return this.http.get(`${hypegraphConfig.base}/${uid}?raw=true&auth_token=${this.userService.getToken()}&api_key=${hypegraphConfig.publicKey}`,
        {responseType: "text"});
    }
    public get_local_glb() : Observable<any>{
        return this.http.get(`./assets/3d/cube.glb`, {responseType: "text"});
      }

    likePost(uid: string): Observable<any> {
        return this.http.post<any>(`${hypegraphConfig.base}/${uid}/likes`, {});
    }

    unlikePost(uid: string): Observable<any> {
        return this.http.delete<any>(`${hypegraphConfig.base}/${uid}/likes`, {});
    }

    deletePost(uid: string): Observable<any> {
        console.log('delete' , uid)
        return this.http.delete<any>(`${hypegraphConfig.base}/${uid}`, {});
    }
}

