import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../@core/data/users.service'
import { Group } from '../../../@core/models/group.model'
import{modalService_data} from'../../../@core/utils/modal.service'
import {FeatherService} from '../../../@core/utils/feather.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StripeCardComponent,   StripeService } from 'ngx-stripe'; 
@Component({
  selector: 'nb-modal-details',
  templateUrl: './details-modal.component.html',
  styleUrls: ['./details-modal.component.scss'],
})
export class ModaldetailsComponent implements OnInit {
//  @ViewChild<
  // @ViewChild(StripeCardComponent) card: StripeCardComponent;
  showGroups: boolean = false
  headtitle : string;
  content_text: string;
  update_card: boolean;
  update_account: boolean;
  // cardOptions: ElementOptions = {
  //   style: {
  //     base: {
  //       iconColor: '#666EE8',
  //       color: '#31325F',
  //       lineHeight: '40px',
  //       fontWeight: 300,
  //       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  //       fontSize: '18px',
  //       '::placeholder': {
  //         color: '#CFD7E0'
  //       }
  //     }
  //   }
  // };
  
  // elementsOptions: ElementsOptions = {  locale: 'fr'};
  
  stripeTest: FormGroup;
  currentUser: any
  constructor(
    private activeModal: NgbActiveModal,
    private userService: UserService,
    public modalService_data : modalService_data,
    private featherService :FeatherService,
    
   private fb: FormBuilder,
   private stripeService: StripeService
  ) {
  }

  ngOnInit() {
    this.init()
  }

  private init() {

    this.stripeTest = this.fb.group({
      name: ['name', [Validators.required]]
    });
    this.currentUser = this.userService.getCurrentUser();
    // when user failed at fist paiement recall this bellow 

    // this.stripeService.confirmPaymentIntent('pi_1FmLWMA1LFKedQT0cB4BHPGx_secret_YZEtHyTm9HKog01LxLd9S1KBv')
    // .subscribe((res)=>{console.log('payinten',res)})

    // this.headtitle = this.modalService.headtitle
    // this.content_text = this.modalService.headtitle
    // this.update_card = this.modalService.headtitle
    // this.update_account = this.modalService.headtitle
    // this.userService
    //   .getUserGroupMemberships({})
    //   .subscribe(
    //     res => {
    //       if (!Array.isArray(res.result.nodes)) {
    //         res.result.nodes = Object.values(res.result.nodes);
    //       }
    //       this.groups = res.result.nodes;
    //     },
    //     errRes => {

    //     },
    // );
  }

  public closeModal() {
    this.activeModal.dismiss();
    this.modalService_data.clean();
  }
public removeMe(id){
    var removeInd =  this.modalService_data.basket.map(el => { return el.id; }).indexOf(id); 
    if(removeInd > -1){
      this.modalService_data.basket.splice(removeInd, 1);
      this.featherService.remove_booked(id).subscribe((res)=>{ 
        this.featherService.get_card_booked().subscribe((res:any)=>{

this.modalService_data.basket = res.data[0]
// console.log('newly remove car basket',this.modalService_data.basket)
        })
      
      })
    } 
}
   
public addCard(cardAndPayTotal?:any){
  // this.stripeService.createPaymentMethod('card', this.card.getCard() ).then(function(result) {
  //   // Handle result.error or result.paymentMethod
  // });
  var mail :any
   mail = { billing_details: { email: this.currentUser.email}}
  this.stripeService.createPaymentMethod('card').subscribe(result =>{
    if (result) { 
       
      this.featherService.modifCard(result.paymentMethod.id, this.currentUser.email ,cardAndPayTotal ,result.paymentMethod.card.last4).subscribe( (res0)=>{
         //console.log('ready to reload show toaster and reload also send an email', res0 )
        if (res0){
            window.location.reload()
       }
      }) 
    } else if (result) {    }
  })

}


  public submit(data: any,option?:string) {
    // console.log('submitteedddd :::::::',data ,'oooook:',option)
    this.activeModal.close({'data': data,'option':option })
    // if (this.selectedGroup) {
    //   this.activeModal.close({'title': title, 'target': this.selectedGroup.uid})
    // } else {
    //   this.activeModal.close({'title': title, 'target': 'me'})
    // }
    //tag should be here 
    // also privacy also . 
    // znd locaton 
  }
}
