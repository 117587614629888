import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from '../../../../node_modules/rxjs';
import { UserService } from '../data/users.service';
import { take, map } from '../../../../node_modules/rxjs/operators';
import { hypegraphConfig } from '../../../api-config';

@Injectable()
export class UsersResolver implements Resolve<Observable<any>> {

  constructor(private userService: UserService) { }

  resolve(route: ActivatedRouteSnapshot) {
    /*let request: Observable<any>;
    if (route.data.following) {
      request = this.userService.getUserFriends({ limit: hypegraphConfig.limit, offset: 0 });
    } else if (route.data.followers) {
      request = this.userService.getUserFriends({ limit: hypegraphConfig.limit, offset: 0 });
    } else {
      request = this.userService.getAllUsers({ limit: hypegraphConfig.limit, offset: 0 });
    }*/
    return this.userService
    .getAllUsers({ limit: hypegraphConfig.limit, offset: 0 })
    .pipe(
      take(1),
      map((res) => res.result),
    );
  }

}
