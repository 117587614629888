import { Injectable } from '@angular/core';
import { hypegraphConfig, vr_tour_prdt } from '../../../api-config';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GroupsResponse, Group } from '../models/group.model';
import { UsersListResponse } from '../models/user.model';


interface GroupsFilteringParams {
    limit?: number,
    offset?: number,
    mine?: boolean
}


interface MembersFilteringParams {
    limit?: number,
    offset?: number,
    groupID?: string
}

@Injectable()
export class GroupsService {

    constructor(
        private http: HttpClient,

    ) {

    }

    getGroups(groups_as_member?:any): Observable<any> {
        let query = ''
        if(groups_as_member){
            
          query = `?group_member_as.${groups_as_member}.active=yes`
        } 
        return this.http.get<any>(`${vr_tour_prdt.base}/delivrable-prdt/${query}`);
    } 
    getGroup(groupID: string): Observable<any> { 
        return this.http.get<any>(`${vr_tour_prdt.base}/delivrable-prdt/${groupID}`);
    }
    createGroup(group: Group): Observable<GroupsResponse> {
        let formData = new FormData()
        let httpParams = new HttpParams()
            .set('name', group.name); 

            if (group.briefdescription !== null && group.briefdescription !== undefined) {
                httpParams = httpParams.set('briefdescription', group.briefdescription);
            } 
            
        if (group.icon_contents && group.icon_filename && group.icon_mimetype) {
            formData.append('icon_contents', group.icon_contents);
            formData.append('icon_filename', group.icon_filename);
            formData.append('icon_mimetype', group.icon_mimetype);
        }

        return this.http.post<GroupsResponse>( `${hypegraphConfig.base}/${hypegraphConfig.site}/groups`,formData, { params: httpParams });
        // return this.http.post<GroupsResponse>(`${hypegraphConfig.base}/${hypegraphConfig.site}/groups`, {}, { params: httpParams });
    }

    updateGroup(group: Group): Observable<any> {
        let formData = new FormData()
        let httpParams = new HttpParams()
            .set('name', group.name);

        if (group.briefdescription !== null && group.briefdescription !== undefined) {
            httpParams = httpParams.set('briefdescription', group.briefdescription);
        } 
        if (group.icon_contents && group.icon_filename && group.icon_mimetype) {
            formData.append('icon_contents', group.icon_contents);
            formData.append('icon_filename', group.icon_filename);
            formData.append('icon_mimetype', group.icon_mimetype);
        }

        return this.http.post(`${hypegraphConfig.base}/${group.uid}`, formData, { params: httpParams });
    }

   

    joinGroup(groupID: string): Observable<any> {
        return this.http.post<any>(`${hypegraphConfig.base}/${groupID}/members`, {});
    }

    leaveGroup(groupID: string): Observable<any> {
        const httpParams = new HttpParams()
            .set('relationship', 'member');
        return this.http.delete(`${hypegraphConfig.base}/${groupID}/members`, { params: httpParams });
    }

    getGroupMembers(params: MembersFilteringParams): Observable<UsersListResponse> {
        let httpParams = new HttpParams();

        if (params.offset !== null && params.offset !== undefined) {
            httpParams = httpParams.set('offset', params.offset.toString());
        }
        if (params.limit !== null && params.limit !== undefined) {
            httpParams = httpParams.set('limit', params.limit.toString());
        }
        return this.http.get<UsersListResponse>(`${hypegraphConfig.base}/${params.groupID}/members`, { params: httpParams });

    }

    deleteGroup(groupID: string): Observable<any> {
        return this.http.delete(`${hypegraphConfig.base}/${groupID}`);
    }
}
