
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {  User, LoginResponse, LoginResult,
  RegisterResponse, UsersListResponse, UserProfileResponse, } from '../models/user.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { hypegraphConfig ,auth} from '../../../api-config';



interface FilteringParams {
  limit?: number,
  offset?: number,
}

@Injectable()
export class UserService {
  isubmitting_login:boolean =false
  errors_login: string[] = [];
  private userData: LoginResult;

  constructor(
    private http: HttpClient,
  ) {
    this.loadUserData();
  }

  logout() {
    this.userData = null;
    localStorage.removeItem('userData');
  }


  getUserProfileData(): Observable<any> {
 
       const  uid = this.getCurrentUser()._id 
    return this.http.get<any>(`${auth
    .base}/users/${uid}`);
  }
  // language=en&notify=true
  register(user: User): Observable<RegisterResponse> {
    var  httpParams 
    if (user.form_p== true || user.username_p!=''){
        httpParams = new HttpParams()
      .set('name', 'spam')
      .set('email', 'spam@spam.spam')
      .set('username', 'spam_un')
      .set('password', 'spam_psw') 
      .set('briefdescription', 'spam_IP');

    }else{
        httpParams = new HttpParams() 
      .set('email', user.email) 
      .set('password', user.password)  
    }
    
    return this.http.post<RegisterResponse>(`${auth
    .base}/users`, {email:user.email,password: user.password  });

  }

  login(user: User): Observable<LoginResponse> { 
    return this.http.post<LoginResponse>(`${auth.base}authentication`, {email:user.email,password: user.password ,strategy: 'local'} );
  }
  exchangeToken(token: string,guid_ue): Observable<LoginResponse> {
    const httpParams = new HttpParams()
      .set('auth_token', token)
      .set('guid_ue', guid_ue);
    return this.http.put<LoginResponse>(`${hypegraphConfig
    .base}/me/token`, {}, { params: httpParams });
  }



  // http://46.101.47.50:8000/services/api/graph/se1/users?name=rese&email=poioijpo@plopo.com&username=popokoik&password=popopolo&subtype=121&briefdescription=dealer&api_key=88c94bfe5312e70b91434be4f5469956dd905a34&request_psw=fh&debug=true
  requestPassword(email) {
    const httpParams = new HttpParams()
      .set('email', email)
      .set('request_psw', 'notnull');
    return this.http.post(`${hypegraphConfig
    .base}/se1/users`, {}, { params: httpParams });
  }

  resetPassword(email,code,psw) {
    const httpParams = new HttpParams()
    .set('email', email)
    .set('request_psw', 'notnull')
    .set('code', code)
    .set('psw', psw);
    return this.http.post(`${hypegraphConfig
    .base}/se1/users`, {}, { params: httpParams });
  }


 

  getToken(): string {
    return this.userData ? this.userData.accessToken : null;
  }

  private loadUserData() { 
    const userDataStr = localStorage.getItem('userData');
    if (userDataStr!== null && userDataStr !== undefined) {
      this.userData = JSON.parse(userDataStr);
    } else {
      this.userData = null;
    }
  }

   

  saveUserData(): void {
    if(this.userData != undefined){ 
    localStorage.setItem('userData', JSON.stringify(this.userData));
    }
  }

  setUserData(userData: LoginResult, remember?: boolean): void {
    this.userData = userData;
    // if (remember) {
      this.saveUserData();
    // }
  }
 updatelocalUserData(newuserdata: any, remember: boolean): void {
    
    if (remember) {
      const userDataStr = localStorage.getItem('userData');
      if (userDataStr) {
        this.userData = JSON.parse(userDataStr);
        this.userData.user.icon =   newuserdata.icon
        this.userData.user.owner_icon =   newuserdata.owner_icon 
      }
      localStorage.setItem('userData',  JSON.stringify(this.userData));
    }
  }

  getCurrentUser(): User {
    return this.userData ? this.userData.user : null;
  }


  isLoggedIn(): boolean {
    return (this.userData !== null && this.userData !== undefined);
  }
//delete account or desactivate . 
//add a carte token on 
//delete a token 
// update a token +4 last number 
// Json paiemen C R U D  
// Json subscribtion C R U D 
//description   location interesss skuls 

updateUserData(params ? : any  , locations?:string, ): Observable<any> {
 
  let httpParams = new HttpParams();
  httpParams = httpParams.set('access_id', "1");
  if (params.location !== null && params.location !== undefined) {
      httpParams = httpParams.set('location', params.location.name.toString());
  }
  if (params.profile !== null && params.profile !== undefined) {
    httpParams = httpParams.set('briefdescription', params.profile.name.toString());
}
// const httpParams = new HttpParams()
    
;
return this.http.put(`${hypegraphConfig
.base}/me/profile`, {}, { params: httpParams });
   

}
  getAllUsers(params: FilteringParams): Observable<UsersListResponse> {
    let httpParams = new HttpParams();
    if (params.limit !== undefined && params.limit !== null) {
      httpParams = httpParams.set('limit', params.limit.toString());
    }
    if (params.offset !== undefined && params.offset !== null) {
      httpParams = httpParams.set('offset', params.offset.toString());
    }
    return this.http.get<UsersListResponse>(`${hypegraphConfig
    .base}/${hypegraphConfig
    .site}/users`, { params: httpParams });
  }


  setNewUserFlag(): void {
    localStorage.setItem('newRegister', 'true');
  }

  clearNewUserFlag(): void {
    localStorage.removeItem('newRegister');
  }

  isNewUser(): boolean {
    return localStorage.getItem('newRegister') ? false && this.isLoggedIn() : false;
  }


  isAdmin(): boolean {
    if(this.userData){
      if(this.userData.user.briefdescription =="Entreprise"|| this.userData.user.admin ==true){
      return true
      }
    }
    // return this.userData ? this.userData.user.admin : false;
  }

  getUserFriends(params: FilteringParams): Observable<UsersListResponse> {
    let httpParams = new HttpParams();
    if (params.limit !== undefined && params.limit !== null) {
      httpParams = httpParams.set('limit', params.limit.toString());
    }
    if (params.offset !== undefined && params.offset !== null) {
      httpParams = httpParams.set('offset', params.offset.toString());
    }
    return this.http.get<UsersListResponse>(`${hypegraphConfig
    .base}/me/friends`, { params: httpParams });
  }


  addFriend(userId: string): Observable<any> {
    const httpParams = new HttpParams()
      .set('friend_uid', userId);
    return this.http.post<any>(`${hypegraphConfig
    .base}/me/friends`, {}, { params: httpParams });
  }

  removeFriend(userId: string): Observable<any> {
    const httpParams = new HttpParams()
      .set('friend_uid', userId);
    return this.http.delete(`${hypegraphConfig
    .base}/me/friends`, { params: httpParams });
  }


  getUserGroupMemberships(params: FilteringParams): Observable<any> {
    let httpParams = new HttpParams();
    if (params.offset !== null && params.offset !== undefined) {
      httpParams = httpParams.set('offset', params.offset.toString());
    }
    if (params.limit !== null && params.limit !== undefined) {
      httpParams = httpParams.set('limit', params.limit.toString());
    }
    return this.http.get<any>(`${hypegraphConfig
    .base}/me/groups/membership`, { params: httpParams });
  }


  getUserLikes(params: FilteringParams): Observable<any> {
    let httpParams = new HttpParams();
    if (params.offset !== null && params.offset !== undefined) {
      httpParams = httpParams.set('offset', params.offset.toString());
    }
    if (params.limit !== null && params.limit !== undefined) {
      httpParams = httpParams.set('limit', params.limit.toString());
    }
    return this.http.get<any>(`${hypegraphConfig
    .base}/me/likes`, { params: httpParams });
  }

}
