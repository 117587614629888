import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';

declare const gtag: any;

@Injectable()
export class AnalyticsService {
  private enabled: boolean;
  private googleAnalyticsKey:string
  private userID: string ;
  private user_caza : string ;
  private post_caza : string ;
  constructor(private location: Location, private router: Router) {
    this.enabled = true;
    this.init_analytic();
  }

public init_analytic (){
 //retrieve analytic id from user info 
 this.googleAnalyticsKey = 'UA-159177419-1'
 this.userID ='46'
 this.user_caza = '41';
 this.post_caza = '51';
// ga('set', 'user_caza', dimensionValue);

  try {

    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.googleAnalyticsKey;
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      
      gtag('config', '` + this.googleAnalyticsKey + `', { 
      'custom_map': {'dimension1': 'user_caza','dimension2': 'post_caza' },
      'page_title' : 'Your custom title',
      'page_path': '` + this.location.path() + `' 
      }
        
     );
      gtag('set', {'dimension1': '` + this.userID + `' })  
      gtag('set', {'user_id': '` + this.userID + `'}) 
    `;
    document.head.appendChild(script2);
  } catch (ex) {
    console.error('Error appending google analytics');
    console.error(ex);
  }

} 
  trackPageViews(msg?:any) {
    if (this.enabled) { 
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
      )
        .subscribe(() => {
          gtag('send', {hitType: 'pageview', page: this.location.path()});
        });
    }
  }

  trackEvent(eventName: string) {
    if (this.enabled) { 
    }
  }

  eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ){  
    gtag('event', eventAction,  {
      'event_category': this.userID,
      'event_label': eventLabel,
      'value': eventValue, 
     'dimension2': this.post_caza
    });
  
  }
}
