import { AfterViewInit, Component, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NbLayoutComponent } from '@nebular/theme';

import { WindowModeBlockScrollService } from '../../services/window-mode-block-scroll.service';
import { LayoutService } from '../../../@core/utils/layout.service';
import {modalService_data} from '../../../@core/utils/modal.service'

import { Router, NavigationEnd } from '@angular/router';
import { takeWhile, filter } from 'rxjs/operators';
import { 
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
  NbSearchService,
} from '@nebular/theme';

import { StateService } from '../../../@core/data/state.service';
import { UserService } from '../../../@core/data/users.service';
// import { Tag, Filter, Category, FiltersState } from '../../../@core/models/filters.model';
 

// TODO: move layouts into the framework 

//remove window mode to enlarge and lose cao view
// fix this bug <nb-layout [center]="layout.id === 'center-column'"    class="window_size">
@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `<nb-layout [center]="layout.id === 'center-column'"    class="window_size">
  
  <!-- *ngIf="modalService_data.header_show" -->
    <nb-layout-header #ng_vc_get_header_size *ngIf="!modalService_data.header_show" fixed   class="get_header_size" style="z-index: 10;" > 
      <ngx-header [position]="sidebar.id === 'start' ? 'normal': 'inverse'"
      ></ngx-header>
    </nb-layout-header>
  
     <nb-sidebar class="menu-sidebar " id="pad_close" tag="menu-sidebar" [state]="MenuState" fixed [end]="sidebar.id === 'end'">
       <div
  style="display: flex; align-items: center; justify-content: flex-end;"
  class="menu_close cursor_me"
  (click)="close_menu()"
>
  <nb-icon icon="close-outline" style="font-size: 32px; font-weight: bold;"></nb-icon>
</div>
    
    <ng-content select="nb-menu"></ng-content>
  
      <div style="position: relative;margin-top: 40px;width: 86%;">

        <nb-card>
          <nb-card-body class="remove_padding">
            <nb-actions fullWidth size="tiny"> 
              <nb-action class="cursor_me" routerLink="/pages/profile" >
                <i class="fa fa-user" style="display: flex"></i>
                <span class="hidmetosmall" >Profile</span>
              </nb-action>
              <nb-action class="cursor_me"  (click)="logout()" >
                <i class="fa fa-user-times" style="display: flex"></i>
                <span class="hidmetosmall">Déconnection</span>
              </nb-action>
            </nb-actions>
  
          </nb-card-body>
        </nb-card>
      </div>
    </nb-sidebar>
  
    <nb-layout-column class="main-content" id="main-content">
      <ng-content select="router-outlet"></ng-content>
    </nb-layout-column>
  
  
    <nb-layout-footer fixed *ngIf='with_footer'>
      <ngx-footer></ngx-footer>
    </nb-layout-footer>
   
  </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements AfterViewInit {

  layout: any = {};
  sidebar: any = {};
  currentTheme: string;
  // tags: Tag[];
  // categories: Category[];
  // filters: Filter[];
  filtersState = 'expanded';
  MenuState = 'collapsed';
  // currentFilters: FiltersState = {};
  private alive = true; 
  with_footer: boolean =false;

  // @ViewChild(NbLayoutComponent, { static: false }) layout: NbLayoutComponent;

  constructor(
    protected searchService: NbSearchService,
    protected menuService: NbMenuService,
    protected themeService: NbThemeService,
    protected bpService: NbMediaBreakpointsService,
    protected sidebarService: NbSidebarService,
    protected UserService: UserService,
    protected stateService: StateService,
    protected router: Router,
    private modalService_data : modalService_data, 
    private layoutService: LayoutService,  
    @Inject(PLATFORM_ID) private platformId,
    private windowModeBlockScrollService: WindowModeBlockScrollService,
  ) {}

  ngAfterViewInit() {
    // if (isPlatformBrowser(this.platformId)) {
    //   this.windowModeBlockScrollService.register(this.layout);
    // }
  }

  ngOnInit() {
    this.init();
    this.initSubscriptions(); 
  }

  init() {
 
    if(this.router.url.toString().lastIndexOf('cao') > 0) this.with_footer= false
    // this.tags = this.filtersService.getTags();
    // this.categories = this.filtersService.getCategories();
    // this.filters = this.filtersService.getFilters();

    
this.router.events
.pipe(filter(event => event instanceof NavigationEnd))
.subscribe( (event: NavigationEnd)  => {  
this.sidebarService.toggle(false, 'menu-sidebar');
this.sidebarService.collapse('menu-sidebar');
// this.setSidebarState();
})
  }


  initSubscriptions() {

    this.stateService.onLayoutState()
      .pipe(takeWhile(() => this.alive))
      .subscribe((layout: string) => this.layout = layout);

    this.stateService.onSidebarState()
      .pipe(takeWhile(() => this.alive))
      .subscribe((sidebar: string) => {
        this.sidebar = sidebar;
      });

   
    // this.themeService.onMediaQueryChange().subscribe(([item, [bpFrom, bpTo]]: [any, ["", ""]]) => { 
    // }); 
    this.themeService.getJsTheme()
      .pipe(takeWhile(() => this.alive))
      .subscribe(theme => {
        this.currentTheme = theme.name;
      });

    this.searchService
      .onSearchSubmit()
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        data => { 
        },
    );
    this.setSidebarState();
      
  }


  setSidebarState() {
    this.filtersState = window.location.href.indexOf('home') === -1 ? 'collapsed' : 'collapsed';
    console.log( this.filtersState)
	   this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(
        (event: NavigationEnd) => {
          this.filtersState = event.url.indexOf('home') === -1 ? 'collapsed' : 'collapsed';
        
        },
    );
  }
close_menu(){ 
  this.sidebarService.toggle(false, 'menu-sidebar');
}
  // onFiltersChanged(filtersState: FiltersState) {
  //   this.currentFilters = { ...this.currentFilters, ...filtersState };
  //   this.applyFilters();
  // }
   
  

  // applyFilters() {
  //   this.router.navigate(['pages/explore'], { queryParams: this.currentFilters })

  // }
 
  ngOnDestroy() {
    this.alive = false;
  }
  logout(){
    this.UserService.logout()
    this.router.navigate(['/auth/login']);
  }
}

