import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad } from '@angular/router';
import { UserService } from '../data/users.service';

@Injectable()
export class NewUserGuard implements CanActivate, CanLoad {
    constructor(
        private router: Router,
        private userService: UserService,
    ) {

    }
    canActivate() {
        return this.checkNotNewUser();
    }

    canLoad() {
        return this.checkNotNewUser();
    }

    checkNotNewUser() {
        const isNewUser = this.userService.isNewUser();
        if (isNewUser) {
            this.router.navigate(['/pages/new-register']);
        }
        return !isNewUser;
    }
}
