import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from '../../../../node_modules/rxjs';
import { take, map } from '../../../../node_modules/rxjs/operators';
import { PostsService } from '../data/posts.service';

@Injectable()
export class PostLikesResolver implements Resolve<Observable<any>> {


    constructor(private postsService: PostsService) { }

    resolve(route: ActivatedRouteSnapshot) {
        const uid = route.paramMap.get('uid');
        return this.postsService
            .getPostLikes({ limit: 0, offset: 0 }, uid)
            .pipe(
                take(1),
                map((res) => res.result),
        );
    }

}
