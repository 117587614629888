export const generalConfig = {
    forms: {
        validations: {
            minLength: 3,
            maxLength: 50,
            emailPattern: '.+@.+\..+',
            passwordMinLength: 6,
        },
    },
    errorMsg: 'An error occurred, please try again later',
    socialLinks: [
        {
            url: 'https://github.com/akveo/nebular',
            target: '_blank',
            icon: 'socicon-github',
        },
        {
            url: 'https://www.facebook.com/akveo/',
            target: '_blank',
            icon: 'socicon-facebook',
        },
        {
            url: 'https://twitter.com/akveo_inc',
            target: '_blank',
            icon: 'socicon-twitter',
        },
    ],
}
