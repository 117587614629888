import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from '../../../../node_modules/rxjs';
import { take, map } from '../../../../node_modules/rxjs/operators';
import { PostsService } from '../data/posts.service';
import { hypegraphConfig } from '../../../api-config';

@Injectable()
export class BlogsProResolver implements Resolve<Observable<any>> {

    constructor(private postsService: PostsService) { }

    resolve(route: ActivatedRouteSnapshot) {
        const mine = route.data.mine;
        const uid = route.paramMap.get('uid');
        const cat =  route.queryParamMap.get('category')
        const tags =  "pro"

        let targetUid;
        if (mine) {
            targetUid = 'me';
        } else if (uid) {
            targetUid = uid
        }
        return this.postsService
            .getLatestBlog({ limit: hypegraphConfig.limit, offset: 0, targetUid },cat,tags)
            .pipe(
                take(1),
                map((res) => res.result),
        );
    }

}
