
import { Injectable } from '@angular/core';
import { NbMenuService } from '@nebular/theme';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from '../data/users.service';


@Injectable()
export class ProfileMenuService {
    constructor(
        private nbMenuService: NbMenuService,
        private router: Router,
        private userService: UserService,
    ) {

    }

    init() {
        this.handleProfileMenu();
    }

    private handleProfileMenu() {
        this.nbMenuService.onItemClick()
            .pipe(
                filter(({ tag }) => tag === 'user-context-menu'),
        )
            .subscribe(
                menu => {
                    this.handleProfileMenItemuClick(menu.item);
                },
        );
    }

    private handleProfileMenItemuClick(menuItem) {
        console.log("click")
        switch (menuItem.id) {
            case 1:
                this.router.navigate(['/pages/profile']);
                break;
            case 2:
                this.userService.logout();
                this.router.navigate(['/auth/logout']);
                break;
        }
    }

}
