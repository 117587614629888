import { Injectable } from '@angular/core';
import { Post, PostsResponse } from '../models/post.model';
import { Observable } from '../../../../node_modules/rxjs';
import { HttpClient, HttpParams } from '../../../../node_modules/@angular/common/http';
import { hypegraphConfig } from '../../../api-config';
import { Md5 } from 'ts-md5/dist/md5'

import { v4 as uuid } from 'uuid'

import { UserService } from './users.service';
import { messageN, messageResponse, messageListResult } from '../models/messageComment.model';


interface MsgComFilteringParams {
    limit?: number,
    offset?: number,
    username?: string,
    targetUid?: string,
}

@Injectable()
export class MsgComService {

    constructor(
        private http: HttpClient,
        private userService: UserService,
    ) { }

   // http://46.101.47.50:8000/services/api/graph/ue52117/messages?subject=hello ho ho &message=messaaaaaaaaaaaaaargr&auth_token=f0f3c38caeae2ba43260ed31d8c0e510

   

  retrieveMsg(){}


    sendNewMsg(msg: messageN): Observable<messageResponse> {  
        let formData = new FormData()
            formData.append('subject',  msg.title)  
            formData.append('message',  msg.description)  
            formData.append('access_id', '0') 
            formData.append('auth_token', this.userService.getToken())  
            return this.http.post<any>(`${hypegraphConfig.base}/${msg.uid}/messages`, formData);
    }
    replyMsg(uid: string): void {
        window.location.href = `${hypegraphConfig.base}/${uid}?raw=true&auth_token=${this.userService.getToken()}`;
    }
    getOneMsg(uid: string): Observable<messageResponse> {
        return this.http.get<messageResponse>(`${hypegraphConfig.base}/${uid}`);
    }
    getlistMsg(uid: string): Observable<messageListResult>{

        return this.http.get<messageListResult>(`${hypegraphConfig.base}/${uid}`);

    }
    // getLatestPosts(params: PostsFilteringParams): Observable<PostsResponse> {
    //     let httpParams = new HttpParams();

    //     if (params.offset !== null && params.offset !== undefined) {
    //         httpParams = httpParams.set('offset', params.offset.toString());
    //     }
    //     if (params.limit !== null && params.limit !== undefined) {
    //         httpParams = httpParams.set('limit', params.limit.toString());
    //     }

    //     const targetUid = params.targetUid || hypegraphConfig.site;
    //     return this.http.get<PostsResponse>(`${hypegraphConfig.base}/${targetUid}/files`
    //         , { params: httpParams });
    // }

    


   

   

    
}

