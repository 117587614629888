import { GroupsService } from '../data/groups.service'; 
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../data/users.service';
import { take, map } from 'rxjs/operators';
import { UserProfileResponse } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class GroupMembersResolver implements Resolve<Observable<any>> {

  constructor(private groupsService: GroupsService,public userService : UserService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> { 
    // Directly return the observable from the service without subscribing to it 
    return this.groupsService.getGroups(this.userService.getCurrentUser()._id).pipe(
      take(1), // Ensure the observable completes after emitting once
      map(response => { 
        return response; // Return the resolved data
      })
    );
  }
}
